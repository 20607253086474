<template>
  <div class="auth d-flex flex-column">
    <ChangePasswordNeed :personAllData="personData"></ChangePasswordNeed>
    <header class="pl-2 pl-sm-5 py-2 d-flex align-items-center">
      <b-img
        class="mr-2 mr-sm-5"
        :src="require('assets/images/logo.svg')"
        :height="`${isMobile ? 48 : 64}px`"
      />
      <!--<span class="px-2">在地醫事人員招生輔導分發及服務平臺</span>-->
      <span class="px-2">公費醫事人員資訊管理平臺</span>
    </header>
    <div class="bottom-word">
      本網站為衛生福利部護理及健康照護司版權所有，未經允許不得以任何形式複製和採用。Copyright
      © 2021<br />本網站建議使用Chrome瀏覽器瀏覽並搭配 1920 x 1080
      (以上)之螢幕解析度，以獲得最佳瀏覽體驗。
      <span style="padding:0px 5px;"> 版本編號: {{ versionTime }}</span>
    </div>
    <!-- <div class="dtc-logo">
      <b-img
        :src="require('assets/images/dtclogo.png')"
        height="85"
        width="235"
      />
    </div> -->

    <b-container class="flex-grow-1 d-flex align-items-center py-5 py-sm-0">
      <div class="w-100">
        <b-row>
          <b-col id="noticement" cols="12" sm="6" class="noticement">
            <h3 class="p-2 mt-3 mt-sm-0">公告訊息</h3>
            <div class="messages">
              <div
                v-for="message in messages"
                :key="message.id"
                class="d-flex p-2 btn"
                @click.stop="callBulletin(message)"
              >
                <span class="pr-2 datetime mr-4" v-text="message.dateTime" />
                <span
                  class="flex-grow-1 content text-left"
                  v-text="message.title"
                />
              </div>
            </div>
          </b-col>

          <b-col id="authFrame" cols="12" sm="6">
            <div class="auth-frame py-2 px-4 mx-auto mt-5 mt-sm-0">
              <div
                class="auth-logo d-flex justify-content-center"
                style="height: 50px"
              >
                <b-img
                  style="transform: translateY(-50%); position: absolute"
                  :src="require('assets/images/user.logo.svg')"
                  height="116px"
                />
              </div>
              <h4 class="mt-3">登入註冊專區</h4>
              <div class="switch-mode pb-1">
                <b-button
                  v-for="btn in btns"
                  size="lg"
                  :disabled="isLoading"
                  :key="btn.value"
                  :class="{ 'active-btn text-primary': mode === btn.value }"
                  variant="transparent"
                  @click.stop="mode = btn.value"
                  >{{ btn.text }}</b-button
                >
              </div>

              <!-- <vueRecaptcha
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"
                size="invisible"
                :sitekey="siteKey"
                :loadRecaptchaScript="true"
              > -->
                <button style="display: none">SUBMIT</button>

                <div class="input-area">
                  <div
                    v-for="(input, idx) in authInput"
                    :key="input.key"
                    :class="{ 'mt-3': idx > 0 }"
                    ref="authInput"
                  >
                    <b-img
                      class="position-absolute"
                      :src="require(`assets/images/${input.icon}.svg`)"
                      width="18px"
                    />
                    <b-input
                      size="lg"
                      @keydown.enter="enterNext(idx, 'authInput')"
                      :class="{ 'is-password': input.key.includes('Password') }"
                      :type="
                        (input.key.includes('Password') ||
                          input.key.includes('rowssap')) &&
                        !input.show
                          ? 'password'
                          : 'text'
                      "
                      varient="transparent"
                      v-model.trim="input.value"
                      :placeholder="input.label"
                    />
                    <b-img
                      @click.stop="input.show = !input.show"
                      v-if="
                        input.key.includes('Password') ||
                          input.key.includes('rowssap')
                      "
                      class="position-absolute password-icon"
                      :src="
                        require(`assets/images/${
                          input.show ? 'eye-pupil' : 'close-eyes'
                        }.svg`)
                      "
                      width="18px"
                    />
                  </div>
                </div>

                <div
                  v-if="mode === 'login'"
                  class="d-flex justify-content-between align-items-center"
                >
                  <b-button
                    variant="transparent"
                    @click.stop="callForgetingModal"
                    class="text-primary px-0"
                    style="white-space: nowrap"
                    >忘記密碼</b-button
                  >
                  <div
                    class="text-danger"
                    v-show="errorMessage.show"
                    v-text="errorMessage.message"
                  ></div>
                </div>

                <div class="d-flex px-3 pb-3 pt-4">
                  <b-button
                    size="lg"
                    type="submit"
                    :disabled="isLoading || errorMessage.show"
                    class="mr-1"
                    block
                    @click.stop="confirmAuth"
                    variant="primary"
                    
                    >確認</b-button
                  >
                  <span />
                  <b-button
                    size="lg"
                    @click.stop="clearFormInputs"
                    :disabled="isLoading"
                    class="ml-1"
                    block
                    variant="outline-primary"
                    >取消</b-button
                  >
                </div>
              <!-- </vueRecaptcha> -->
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-modal
      size="sm"
      v-model="noticeModal.show"
      ok-only
      ok-title="關閉"
      @ok="showError = false"
      hide-header
      centered
      >{{ noticeModal.message }}</b-modal
    >

    <b-modal
      v-model="bulletinModal.show"
      :title="bulletinModal.title"
      header-bg-variant="primary"
      hide-footer
      header-text-variant="light"
      size="lg"
      centered
    >
      <div class="bulletin-content" v-html="bulletinModal.content" />
    </b-modal>

    <b-modal
      v-model="showForgetPwd"
      title="忘記密碼"
      header-bg-variant="primary"
      :ok-disabled="showForgetErrMsg"
      header-text-variant="light"
      size="sm"
      ok-title="送出"
      cancel-title="取消"
      @ok="forgetingPassword"
      centered
    >
      <div class="input-area">
        <div
          v-for="(input, idx) in forGetInputs"
          :key="input.key"
          :class="{ 'mt-3': idx > 0 }"
          ref="forgetInput"
        >
          <b-img
            class="position-absolute"
            :src="require(`assets/images/${input.icon}.svg`)"
            width="18px"
          />
          <b-input
            size="lg"
            @keydown.enter="enterNext(idx, 'forgetInput')"
            :type="input.key.includes('Password') ? 'password' : 'text'"
            varient="transparent"
            v-model.trim="input.value"
            :placeholder="input.label"
          />
        </div>
      </div>
      <div v-show="showForgetErrMsg" class="text-danger">所有欄位不得為空</div>
    </b-modal>
  </div>
</template>

<script>
//import vueRecaptcha from "vue-recaptcha";
import ChangePasswordNeed from "@/components/ChangePasswordNeed.vue";
import moment from "moment";
import { domainObject } from "@/constant.js";
import { store } from "@/store/global.js";
export default {
  name: "Login",
  components: {
    //vueRecaptcha,
    ChangePasswordNeed,
  },
  data() {
    return {
      versionTime: window.versionTime,
      personData: {},
      test: false,
      apiUrl: domainObject.platformOriginal,
      //siteKey: "6Leagt4UAAAAAJmujcovVb8dqNeTFbWwie5sV3sE",
      siteKey: "6LcY25wfAAAAAGTydvWBcdVupaNhz4zCn18Rj1kD",
      messages: [],
      showForgetPwd: false,
      mode: "login",
      isLoading: false,
      lockTime: 1000 * 60 * 15,
      btns: [
        { value: "login", text: "登入" },
        { value: "register", text: "註冊" },
      ],
      inputs: [
        {
          icon: "user",
          label: "帳號(公費生請輸入身分證)",
          value: "",
          key: "nmspic",
          isRegist: true,
        },
        {
          icon: "password",
          label: "密碼",
          value: "",
          key: "drowssap",
          isRegist: true,
          show: false,
        },
        {
          icon: "password",
          label: "確認密碼",
          value: "",
          key: "ConfirmPassword",
          isRegist: false,
          show: false,
        },
        {
          icon: "email",
          label: "郵件信箱",
          value: "",
          key: "Email",
          isRegist: false,
        },
      ],
      forGetInputs: [
        {
          icon: "user",
          label: "帳號",
          value: "",
          key: "nmspic",
          isRegist: true,
        },
        {
          icon: "email",
          label: "郵件信箱",
          value: "",
          key: "email",
          isRegist: false,
        },
      ],
      showForgetErrMsg: false,
      errorMessage: {
        show: false,
        message: "",
      },
      noticeModal: {
        show: false,
        message: "",
      },
      bulletinModal: {
        show: false,
        title: "",
        content: "",
      },
      win: {
        width: 0,
        height: 0,
      },
      sendingData: {},
      reswll: {},
    };
  },
  computed: {
    authInput() {
      return this.inputs.filter(
        ({ isRegist }) => isRegist || this.mode === "register"
      );
    },
    isMobile() {
      return this.win.width < 760;
    },
  },
  created() {
    window.axios.get("Bulletin/Get").then((res) => {
      this.messages = res.map(({ Id, Subject, Content, CreateTime }) => ({
        id: Id,
        dateTime: this.$formatDate(CreateTime),
        title: Subject,
        content: Content,
      }));
    });

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.switchCols();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async forgetingPassword(evt) {
      evt.preventDefault();
      if (!Object.values(this.forGetInputs).every(({ value }) => value)) {
        this.showForgetErrMsg = true;
        setTimeout(() => {
          this.showForgetErrMsg = false;
        }, 1000);
        return;
      }
      let data = this.forGetInputs.reduce(
        (acc, { value, key }) => ({ ...acc, [key]: value }),
        {}
      );

      try {
        data.account = "";
        data.account = data.nmspic;

        const res = await window.axios.post("auth/SendForgetPassword", data);

        this.showForgetPwd = false;
        this.noticeModal.message = "請至信箱重新修改密碼";
      } catch (error) {
        this.noticeModal.message = error;
      } finally {
        this.noticeModal.show = true;
      }
    },
    callForgetingModal() {
      this.showForgetPwd = true;
    },
    clearFormInputs() {
      this.inputs.forEach((ipt) => {
        ipt.value = "";
      });
    },
    enterNext(idx, name) {
      if (this.$refs[name][idx + 1]) {
        this.$refs[name][idx + 1].querySelector("input").focus();
      } else if (this.$refs[name].length === idx + 1) {
        if (name === "authInput") {
          this.confirmAuth();
        } else {
          this.forgetingPassword();
        }
      }
    },
    digtialToStr(time, num = 2) {
      return (new Array(num).fill(0).join("") + time).slice(-num);
    },
    confirmAuth() {
      this.authInput.forEach((input) => {
        input.value = input.value.replace(/<[^>]*>?/gm, ""); // 去掉任何標籤
      });
      if (localStorage.getItem("invalidData")) {
        const existInvalidDataList = JSON.parse(
          localStorage.getItem("invalidData")
        );

        const { nmspic } = this.authInput.reduce(
          (acc, { value, key }) => ({ ...acc, [key]: value }),
          {}
        );
        const existInvalidData = existInvalidDataList.find(
          (s) => s.nmspic === nmspic
        );

        if (existInvalidData) {
          const { count, time } = existInvalidData;
          const isOver15Min = Date.now() - time > this.lockTime;

          if (count >= 3 && !isOver15Min) {
            // 達三次失敗 並在15分鐘內
            this.bulletinModal.title = "錯誤";

            const restOfTime = Date.now() - time;

            const duration = moment.duration(
              this.lockTime - (Date.now() - time),
              "milliseconds"
            );

            this.bulletinModal.content = `輸入帳號: ${nmspic} ，該帳號達三次失敗 請在${this.digtialToStr(
              duration.minutes()
            )}分${this.digtialToStr(duration.seconds())}秒後再登入`;
            this.bulletinModal.show = true;
            return;
          }
          if (count >= 3 && isOver15Min) {
            // 達三次失敗 並在超過15分鐘
            localStorage.setItem(
              "invalidData",
              JSON.stringify(
                // existInvalidDataList.filter(({ nmspic }) => nmspic !== Account)
                existInvalidDataList.filter((s) => s.nmspic !== nmspic)
              )
            );
          }
        }
      }

      if (this.isLoading || this.errorMessage.show) return;

      this.sendingData = this.authInput.reduce(
        (acc, { value, key }) => ({ ...acc, [key]: value }),
        {}
      );

      if (!Object.values(this.sendingData).every((v) => v)) {
        this.$bvToast.toast(`尚有資料未輸入，請輸入完整`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      if (
        this.mode === "register" &&
        this.sendingData.drowssap !== this.sendingData.ConfirmPassword
      ) {
        this.$bvToast.toast(`密碼不一致，請進行調整`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        this.sendingData = {};
        return;
      }
      //this.$refs.recaptcha.execute();
      this.onVerify("test"); // for testing without recaptcha
      this.isLoading = true;
    },

    async onVerify(recaptchaToken) {
      if (this.mode === "register") {
        this.sendingData = Object.entries(this.sendingData).reduce(
          (acc, [k, v]) => {
            if (k !== "ConfirmPassword") {
              return { ...acc, [k.toLowerCase()]: v };
            } else {
              return acc;
            }
          },
          {}
        );
      }

      // const authUrl =
      //   "auth/" +
      //   (this.mode === "register"
      //     ? `RegisterAccount?${Object.entries(this.sendingData)
      //         .map(([key, value]) => key + "=" + value)
      //         .join("&")}`
      //     : `Login?${Object.entries(this.sendingData)
      //         .map(([key, value]) => key + "=" + value)
      //         .join("&")}`);
      // console.log("process.env.VUE_APP_APIURL", process.env.VUE_APP_APIURL);
      try {
        sessionStorage.setItem("docrkkt", "");
        this.isLoading = true;
        this.sendingData.account = "";
        this.sendingData.account = this.sendingData.nmspic;

        const authUrl =
          this.mode === "register"
            ? `auth/RegisterAccount?account=${this.sendingData.account}&drowssap=${this.sendingData.drowssap}&email=${this.sendingData.Email}`
            : `auth/Login?account=${this.sendingData.account}&drowssap=${this.sendingData.drowssap}`;

        const res = await window.axios.post(authUrl, this.sendingData);
        this.reswll = { ...res };

        if (this.mode === "login") {
          //for 定期修改密碼
          if (this.reswll.changeDrowssap) {
            this.personData = JSON.parse(JSON.stringify(this.reswll));

            sessionStorage.setItem("token", JSON.stringify(this.reswll.token));
            this.$bvModal.show("changePasswordForNeed");
          } else {
            sessionStorage.setItem("token", JSON.stringify(this.reswll.token));

            sessionStorage.setItem(
              "fullname",
              JSON.stringify(this.reswll.FullName)
            );
            sessionStorage.setItem(
              "functionIds",
              JSON.stringify(this.reswll.functionIds)
            );
            sessionStorage.setItem(
              "darcok",
              JSON.stringify(this.reswll.HealthCode)
            );
            sessionStorage.setItem(
              "docrkkt",
              JSON.stringify(this.sendingData.nmspic)
            );
            sessionStorage.setItem(
              "igfjh",
              JSON.stringify(this.reswll.Identifier)
            );
            sessionStorage.setItem("hfjjj", JSON.stringify(this.reswll.id));
            this.$router.push("/home");
          }
        } else {
          this.$bvToast.toast(`驗證通知信已送出，請檢查信箱`, {
            title: `註冊成功!`,
            autoHideDelay: 5000,
            variant: "success",
          });

          this.clearFormInputs();
        }
      } catch (err) {
        let type = "";
        if (this.mode === "login") {
          type = "登入";
          this.$bvToast.toast(`${err.response ? err.response.data : err}`, {
            title: `${type}錯誤!`,
            autoHideDelay: 5000,
            variant: "danger",
          });

          const existInvalidDataList = localStorage.getItem("invalidData")
            ? JSON.parse(localStorage.getItem("invalidData"))
            : [
                {
                  nmspic: this.sendingData.nmspic,
                  count: 1,
                  time: Date.now(),
                },
              ];

          if (localStorage.getItem("invalidData")) {
            const existInvalidData = existInvalidDataList.find(
              ({ nmspic }) => nmspic === this.sendingData.nmspic
            );

            if (
              existInvalidData &&
              Date.now() - existInvalidData.time < this.lockTime
            ) {
              existInvalidData.count += 1;
              existInvalidData.time = Date.now();
            } else if (!existInvalidData) {
              existInvalidDataList.push({
                nmspic: this.sendingData.nmspic,
                count: 1,
                time: Date.now(),
              });
            }
          }
          localStorage.setItem(
            "invalidData",
            JSON.stringify(existInvalidDataList)
          );
        } else {
          type = "註冊";
          this.$bvToast.toast(`${err.response ? err.response.data : err}`, {
            title: `${type}錯誤!`,
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      } finally {
        this.isLoading = false;
      }
    },

    handleResize() {
      this.win.width = window.innerWidth;
      this.win.height = window.innerHeight;
    },
    switchCols() {
      const noticement = document.getElementById("noticement");
      const authFrame = document.getElementById("authFrame");
      if (this.isMobile) {
        authFrame.after(noticement);
      } else {
        noticement.after(authFrame);
      }
    },
    onExpired: function() {
      this.isLoading = false;
      console.log("Expired");
    },
    /*
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },*/
    callBulletin({ title, content }) {
      this.bulletinModal.title = title;
      this.bulletinModal.content = content;
      this.bulletinModal.show = true;
    },
  },
  watch: {
    isMobile(val) {
      this.switchCols();
    },
    "bulletinModal.show"(val) {
      if (!val) {
        ["title", "content"].forEach((k) => {
          this.bulletinModal[k] = "";
        });
      }
    },
    "errorMessage.show"(val) {
      if (val)
        setTimeout(() => {
          this.errorMessage.show = false;
          this.errorMessage.message = "";
        }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  background: transparent url("~assets/images/sign_bg.svg") no-repeat center
    center / cover;
  height: 100vh;
}
header {
  background-color: #7e2ca3;
  font-size: 26px;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  font-family: 微軟正黑體;
  line-height: 45px;
}
::v-deep.bulletin-content {
  ul,
  ol {
    margin-inline-start: 1.5em;
  }
}
.noticement {
  h3 {
    font-size: 40px;
    color: #7e2ca3;
    border-bottom: 3px solid #7e2ca3;
  }
  .messages {
    max-height: 50vh;
    overflow-y: auto;
    > div {
      border-bottom: 1px solid #999;
      &:nth-last-child(1) {
        border-bottom: none;
      }
      span {
        font-size: 24px;
        font-weight: lighter;
        &.datetime {
          white-space: nowrap;
          color: #7e2ca3;
        }
        &.content {
          color: #404040;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
.auth-frame {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000029;
  border-radius: 8px;
  max-width: 390px;
  h4 {
    color: #7e2ca3;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .active-btn {
    font-weight: bold;
  }
}
.input-area {
  > div {
    position: relative;
    input {
      background-color: #f5f5f5;
      padding-left: 43px;
      border: none;
      &.is-password {
        padding-right: 43px;
      }
    }
    img {
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      &.password-icon {
        left: auto;
        right: 15px;
        cursor: pointer;
      }
    }
  }
}
.bottom-word {
  position: fixed;
  bottom: 2%;
  left: 50%;
  font-weight: bolder;
  font-family: 微軟正黑體;
  transform: translateX(-50%);
  min-width: 977px;
}
.dtc-logo {
  position: fixed;
  bottom: 3.6%;
  left: 4.1%;
}
@media only screen and (max-width: 1400px) {
  .dtc-logo {
    bottom: 8%;
  }
}

@media only screen and (max-width: 760px) {
  header {
    font-size: 18px;
    line-height: 1.2;
  }

  .noticement {
    h3 {
      text-align: center;
      font-size: 24px;
    }
    .messages {
      max-height: 50vh;
      > div span {
        font-size: 18px;
      }
    }
  }
}
</style>
